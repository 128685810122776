<div class="col-lg-12 wrapper-top-content">
    <div class="ibox">
        <div class="ibox-title" style="padding-right:20px;">
            <h5>Solicitação de Orçamento {{_orcamento.codigo}}: </h5>
            <div style="float: right;"> </div>
        </div>
        <div class="ibox-content">

            <app-cabecalho-orcamento *ngIf="_orcamento_criado"></app-cabecalho-orcamento>

            <div class="table-responsive table-padding-3 min-height-grid-insumo
                div-table-fixed margin-top-15" *ngIf="_carregamento_concluido">
                <!-- table-striped table-hover table-bordered -->
                <table class="table table-striped table-hover table-bordered">
                    <thead class="thead-fixed">
                        <tr class="action-thead">
                            <td class="text-right" [attr.colspan]="(_ha_anexo &&
                                (_cotacao.recebimento_produto == 2)) ? 7 :
                                (_ha_anexo || (_cotacao.recebimento_produto ==
                                2)) ? 6 : 5">
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                            <td class="text-center">

                                <label class="text-center
                                    label-checked-center">
                                    <label for="defaultCheck4"
                                        class="margin-buttom-5">
                                        ICMS
                                    </label><br />
                                <input class="" type="checkbox" (click)="ClickCheckedIcmsTemp(_orcamento,
                                        $event);" [checked]="!_orcamento.ha_icms" value="categoria.id" id="defaultCheck4">
                                </label>
                            </td>
                            <td class="text-center">
                                <label class="text-center
                                    label-checked-center">
                                    <label for="defaultCheck5"
                                        class="margin-buttom-5">
                                        IPI
                                    </label><br />

                                <input class="" type="checkbox" (click)="ClickCheckedIpiTemp(_orcamento,
                                        $event);" [checked]="!_orcamento.ha_ipi" value="categoria.id" id="defaultCheck5">
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <th class="th-sequece-produto" rowspan="2">Estoque</th>
                            <th class="th-sequece-produto" rowspan="2">Item</th>
                            <th class="th-nome-produto" rowspan="2">Nome do Insumo</th>
                            <th class="text-center th-select-unity column-unidade" rowspan="2">Marca</th>
                            <th class="text-center th-select-unity column-unidade" rowspan="2">Unidade</th>
                            <th class="text-center th-input-padrao-80" rowspan="2">Quantidade</th>
                            <th class="text-center th-select-unity" rowspan="2" *ngIf="_ha_anexo">Anexo</th>
                            <th class="text-center th-select-unity" rowspan="2" *ngIf="(_cotacao.recebimento_produto == 2)">Entrega Parcial</th>
                            <th class="text-center" colspan="3">Preço R$</th>
                            <th class="text-center" colspan="3">Impostos R$</th>
                            <th class="text-center th-input-padrao-120" rowspan="2">Valor Total - R$</th>
                        </tr>
                        <tr>
                            <th class="text-center th-input-padrao-100">Registrado</th>
                            <th class="text-center th-input-padrao-100 bg-warning">Sugerido</th>
                            <th class="text-center th-input-padrao-100">Orçado</th>
                            <th class="text-center th-input-padrao-65">ICMS %</th>
                            <th class="text-center th-input-padrao-65">IPI %</th>
                            <th class="text-center th-input-padrao-65">Vlr.IPI</th>
                        </tr>
                    </thead>
                    <tbody class="" *ngFor="let produto of _list_produtos">

                        <tr class="{{!produto._index_impar_par ?
                            'tr-background-impar':'tr-background-par'}}
                            {{produto._orcamento_produto.estoque ?
                            'tr-checked-stock-of':''}}">
                            <td class="text-center th-sequence-produto">
                                <input class="" type="checkbox" [checked]="!produto._orcamento_produto.estoque" (click)="ClickCheckedTemp(produto,
                                    $event)" value="produto.id">
                            </td>
                            <td class="text-center th-sequence-produto">{{produto._index}}</td>
                            <td class="td-nome-produto">{{produto.nome}}</td>
                            <td class="td-nome-produto">
                                <span *ngIf="!(produto._marcas && produto._marcas.length > 0)">-</span>
                                <select *ngIf="(produto._marcas && produto._marcas.length > 0)" class="form-control" [(ngModel)]="produto._orcamento_produto.marca_id" (change)="OnChangeProductTemp(produto._orcamento_produto,
                                produto.id)">
                                    <option *ngFor="let marca of produto._marcas" value="{{marca.id}}">{{marca._descricao}}</option>
                                </select>
                            </td>
                            <td class="text-center th-select-unity">{{produto._unidade_nome}}</td>
                            <td class="text-center th-input-stock">{{produto._quantidade}}</td>
                            <td class="text-center th-action-anexo" *ngIf="_ha_anexo">
                                <i *ngIf="produto.anexo && produto.anexo !=
                                    'null'" class="fa fa-file-alt btn-link
                                    cursor-pointer" (click)="MostrarFile(produto)"></i>
                            </td>
                            <td class="text-center th-action-parcial" *ngIf="(_cotacao.recebimento_produto == 2)">
                                <button class="btn btn-sm-grid btn-link">
                                    <i class="fa fa-truck"
                                        (click)="OpenModalTrunck(produto)"></i>
                                </button>
                                <small class="btn-link cursor-pointer"></small>
                            </td>
                            <td class="text-right th-input-stock">R$ {{produto._orcamento_produto._preco_registrado}}</td>
                            <td class="text-right th-input-stock">R$ {{produto._orcamento_produto._preco_sugerido}}</td>
                            <td class="text-left th-input-price">
                                <!--readonly-->
                                <input class="form-control input-price" currencyMask [options]="{ prefix: 'R$ ',
                                    thousands: '.', decimal: ',' }" (ngModelChange)="OnChangeProductTemp(produto._orcamento_produto,
                                    produto.id)" [(ngModel)]="produto._orcamento_produto._preco" [disabled]="produto._orcamento_produto.estoque" type="text" placeholder="0,00">
                            </td>
                            <td class="text-center th-input-stock">
                                <input class="form-control input-stock" [disabled]="produto._orcamento_produto.estoque
                                    || _orcamento.ha_icms" type="number" (ngModelChange)="OnChangeProductTemp(produto._orcamento_produto,
                                    produto.id)" [(ngModel)]="produto._orcamento_produto._icms" placeholder="0">
                            </td>
                            <td class="text-center th-input-stock">
                                <input class="form-control input-stock" [disabled]="produto._orcamento_produto.estoque
                                    || _orcamento.ha_ipi" type="number" (ngModelChange)="OnChangeProductTemp(produto._orcamento_produto,
                                    produto.id)" [(ngModel)]="produto._orcamento_produto._ipi" placeholder="0">
                            </td>
                            <td class="text-right th-input-stock">R$ {{produto._orcamento_produto._preco_ipi}}
                            </td>
                            <td class="text-right th-input-stock">R$ {{produto._orcamento_produto._preco_total}}
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <div class="form-group row">


                <div class="col-sm-{{(_cotacao.tipo_frete == 3)?'4':'8'}}
                    text-termo">
                    <span>Valores expressos em moeda R$ real brasileiro;</span><br>
                    <span>Data de Entrega a contar a partir da confirmação do
                        pedido;</span><br>
                    <span>A descarga do material é de responsabilidade do
                        cliente;</span><br>
                    <span>Confira seu produto no ato da entrega/coleta;</span><br>
                    <span>Vendas no Cartão BNDES estão sujeitas à
                        disponibilidade de crédito do cliente junto a
                        instituição financeira emitente do cartão;</span><br>
                    <span>Estão excluídos de nosso fornecimento todo material ou
                        serviço não espeficado neste documento;</span><br><br>
                    <label><b>Verifique as datas das entregas parciais.</b></label><br>
                    <!--
                    <div>
                        <label class="btn-checked" (click)="ClickLiTermos()">
                            <span class="fa fa-check {{_li_termos ?
                                'active':''}}"></span>
                        </label>
                        <label class="margin-left-10 list-cor">Li e concordo com
                            os termos.</label>
                    </div>
                    -->
                </div>

                <div class="col-sm-4">
                    <table class="table table-striped table-bordered
                        table-aliquota" style="border: 0;">
                        <thead class="th-checked-tipo-frete" *ngIf="_cotacao.tipo_frete == 3 && !(_orcamento.tipo == 1 && _orcamento.cif == 1)">
                            <th colspan="2">
                                <div class="checked-tipo-frete">
                                    <label class="">
                                        <input class="" type="checkbox"
                                            (click)="selectCif($event)"
                                            [checked]="!_orcamento.cif"
                                            value="1" id="defaultCheck5">
                                        <label for="defaultCheck5"
                                            class="margin-left-10 descricao-checked" ><b>Valor para entrega CIF</b></label>
                                    </label>
                                </div>
                            </th>
                        </thead>
                        <tbody class="{{(_orcamento.cif) ?'opacity-3':''}}">
                            <tr class="tr-background-impar">
                                <td class="text-left">
                                    <b>Subtotal</b></td>
                                <td class="th-input-padrao-120 text-right">R$ {{_orcamento._subtotal}}
                                </td>
                            </tr>
                            <tr class="tr-background-impar">
                                <td class="text-left" style="border:0;"></td>
                                <td class="th-input-padrao-120 text-right" style="border:0;"></td>
                            </tr>

                            <tr *ngFor="let aliquota of _aliquotas" class="tr-background-impar">
                                <td><i class="fa fa-trash-alt fa-icon-left" *ngIf="!(_orcamento.cif)" (click)="DeleteAliquotaCifTemp(aliquota)"></i> {{aliquota.descricao}}
                                </td>
                                <td class="th-input-padrao-120 text-right">R$ {{aliquota._valor}}
                                </td>
                            </tr>
                            <tr class="input-td tr-background-impar" *ngIf="!(_orcamento.cif)">

                                <td class="text-left table-padding-3">
                                    <button class="btn btn-primary fa-td-left" [hidden]="!_aliquota.descricao || !_aliquota.valor" (click)="AdicionarAliquotaCifTemp(_aliquota)">
                                        Salvar
                                    </button>
                                    <input class="form-control" type="text" [(ngModel)]="_aliquota.descricao" placeholder="Exemplo: Transporte">
                                </td>
                                <td class="text-left th-input-padrao-120
                                    table-padding-3">
                                    <!--readonly-->
                                    <input class="form-control" currencyMask [options]="{ prefix: 'R$ ',
                                        thousands: '.', decimal: ',' }" [(ngModel)]="_aliquota.valor" type="text" placeholder="0,00">
                                </td>

                            </tr>
                            <tr class="tr-background-impar">
                                <td class="text-left" style="border:0;"></td>
                                <td class="th-input-padrao-120 text-right" style="border:0;"></td>
                            </tr>
                            <tr class="tr-background-par">
                                <td class="text-left"><b>VALOR TOTAL</b></td>
                                <td class="th-input-padrao-120 text-right"><b>R$
                                        {{_orcamento._total}}</b></td>
                            </tr>




                            <!-- ini anexo  proposta fob/cif-->
                            <tr>
                                <td colspan="2" class="text-right no-padding" style="border:0px; padding-top: 15px !important">
                                    <div style="width: 100%;" class="fileUpload
                                        btn btn-secondary" *ngIf="!((_orcamento.file_proposta_fob
                                        && _cotacao.tipo_frete != 3) ||
                                        (_orcamento.file_proposta_cif))">
                                        <span><i class="fa fa-save"></i>&nbsp;&nbsp;
                                            {{(_orcamento.file_proposta_cif ||
                                            ((_orcamento.file_proposta_fob) && _cotacao.tipo_frete != 3)) ? 'Substituir anexo' : 'Anexar'}}
                                            Proposta <span
                                                *ngIf="_cotacao.tipo_frete ==
                                                3">CIF</span></span>
                                        <input #fileInput type="file" name="logo" id="file" class="upload" placeholder="Anexar proposta" (change)="uploadFile(fileInput, 1)">
                                    </div>

                                    <div *ngIf="(_orcamento.file_proposta_fob &&
                                        _cotacao.tipo_frete != 3)" style="margin-bottom: 10px;">

                                        <div class="text-left" (click)="carregarArquivoPropota(_orcamento.id,
                                            _orcamento.file_proposta_fob)">
                                            <b>Proposta:</b>&nbsp;&nbsp;{{_orcamento.file_proposta_fob}}
                                        </div>
                                        <button class="btn btn-link" (click)="carregarArquivoPropota(_orcamento.id,
                                            _orcamento.file_proposta_fob)"><i
                                                class="fa fa-search"></i><br />
                                            Ver Anexo</button> &nbsp;&nbsp;
                                        <button class="btn btn-link fileUpload">
                                            <span><i class="fa fa-save"></i><br
                                                    />Subtituir</span>
                                            <input #fileInput
                                                type="file"
                                                name="logo"
                                                id="file"
                                                class="upload"
                                                placeholder="Anexar
                                                proposta"
                                                (change)="uploadFile(fileInput,
                                                2)">
                                        </button> &nbsp;&nbsp;


                                        <button class="btn btn-link" (click)="removeFile(2)"><i
                                                class="fa fa-trash"></i><br
                                                />
                                            Excluir</button>
                                    </div>
                                    <div *ngIf="(_orcamento.file_proposta_cif)">
                                        <div class="text-left" style="margin-bottom: 10px;" (click)="carregarArquivoPropota(_orcamento.id, _orcamento.file_proposta_cif)">
                                            <b>Proposta:</b>&nbsp;&nbsp;{{_orcamento.file_proposta_cif}}
                                        </div>
                                        <button class="btn btn-link" (click)="carregarArquivoPropota(_orcamento.id,
                                            _orcamento.file_proposta_cif)">
                                            <i class="fa fa-search"></i><br/>
                                                Ver Anexo</button> &nbsp;&nbsp;
                                        <button class="btn btn-link
                                                fileUpload">
                                                <span><i class="fa fa-save"></i><br
                                                        />Subtituir</span>
                                                <input #fileInput
                                                    type="file"
                                                    name="logo"
                                                    id="file"
                                                    class="upload"
                                                    placeholder="Anexar proposta"
                                                    (change)="uploadFile(fileInput, 1)">
                                            </button> &nbsp;&nbsp;
                                        <button class="btn btn-link" (click)="removeFile(1)"><i class="fa fa-trash"></i><br />Excluir</button>
                                    </div>
                                </td>
                            </tr>
                            <!-- fim anexo  proposta fob/cif-->



                        </tbody>
                    </table>
                </div>




                <div class="col-sm-4" *ngIf="_cotacao.tipo_frete == 3">
                    <table class="table table-striped table-bordered
                        table-aliquota" style="border: 0; ">
                        <thead class="th-checked-tipo-frete">
                            <th colspan="2" *ngIf="_cotacao.tipo_frete == 3 && !(_orcamento.tipo == 1 && _orcamento.fob == 1)">
                                <div class="checked-tipo-frete">
                                    <label class="">
                                        <input class="" type="checkbox"
                                            (click)="selectFob($event)"
                                            [checked]="!_orcamento.fob"
                                            value="1" id="defaultCheck4">
                                        <label for="defaultCheck4"
                                            class="margin-left-10 descricao-checked"><b>Valor
                                                para entrega FOB</b></label>
                                    </label>
                                </div>
                            </th>
                        </thead>
                        <tbody class="{{(_orcamento.fob) ?'opacity-3':''}}">
                            <tr class="tr-background-impar">
                                <td class="text-left">
                                    <b>Subtotal</b>
                                </td>
                                <td class="th-input-padrao-120 text-right">R$ {{_orcamento._subtotal}}
                                </td>
                            </tr>
                            <tr class="tr-background-impar">
                                <td class="text-left" style="border:0;"></td>
                                <td class="th-input-padrao-120 text-right" style="border:0;"></td>
                            </tr>

                            <tr *ngFor="let aliquota of _aliquotas_fob" class="tr-background-impar">
                                <td><i class="fa fa-trash-alt fa-icon-left" *ngIf="!(_orcamento.fob)" (click)="DeleteAliquotaFobTemp(aliquota)"></i> {{aliquota.descricao}}
                                </td>
                                <td class="th-input-padrao-120 text-right">R$ {{aliquota._valor}}
                                </td>
                            </tr>
                            <tr class="input-td tr-background-impar" *ngIf="!(_orcamento.fob)">

                                <td class="text-left table-padding-3">
                                    <button class="btn btn-primary fa-td-left" [hidden]="!_aliquotaFob.descricao || !_aliquotaFob.valor" (click)="AdicionarAliquotaFobTemp(_aliquotaFob)">
                                        Salvar
                                    </button>
                                    <input class="form-control" type="text" [(ngModel)]="_aliquotaFob.descricao" placeholder="Exemplo: ICMS">
                                </td>
                                <td class="text-left th-input-padrao-120
                                    table-padding-3">
                                    <!--readonly-->
                                    <input class="form-control" currencyMask [options]="{ prefix: 'R$ ',
                                        thousands: '.', decimal: ',' }" [(ngModel)]="_aliquotaFob.valor" type="text" placeholder="0,00">
                                </td>
                            </tr>
                            <tr class="tr-background-impar">
                                <td class="text-left" style="border:0;"></td>
                                <td class="th-input-padrao-120 text-right" style="border:0;"></td>
                            </tr>
                            <tr class="tr-background-par">
                                <td class="text-left"><b>VALOR TOTAL</b></td>
                                <td class="th-input-padrao-120 text-right"><b>R$
                                        {{_orcamento._total_fob}}</b></td>
                            </tr>


                            <!-- ini anexo  proposta fob-->
                            <tr>
                                <td colspan="2" class="text-right
                                        no-padding" style="border:0px; padding-top: 15px !important;">

                                    <div style="width: 100%;" *ngIf="!(_orcamento.file_proposta_fob)" class="fileUpload
                                            btn btn-secondary">
                                        <span><i class="fa fa-save"></i>&nbsp;&nbsp;
                                                {{(_orcamento.file_proposta_fob ||
                                                ((_orcamento.file_proposta_cif || _orcamento.file_proposta_fob)
                                                && _cotacao.tipo_frete != 3)) ? 
                                                'Substituir ' : 'Anexar'}}
                                                Proposta FOB</span>
                                        <input #fileInput type="file" name="logo" id="file" class="upload" placeholder="Anexar
                                                proposta" (change)="uploadFile(fileInput,
                                                2)">
                                    </div>
                                    <div *ngIf="(_orcamento.file_proposta_fob)">
                                        <div class="text-left" style="margin-bottom:
                                                10px;">
                                            <b>Proposta:</b>&nbsp;&nbsp;<span class="btn btn-link" (click)="carregarArquivoPropota(_orcamento.id,
                                                    _orcamento.file_proposta_fob)">{{_orcamento.file_proposta_fob}}</span>
                                        </div>
                                        <button class="btn btn-link" (click)="carregarArquivoPropota(_orcamento.id,
                                                _orcamento.file_proposta_fob)"><i class="fa fa-search"></i><br />
                                                Ver Anexo</button> &nbsp;&nbsp;
                                        <button class="btn btn-link
                                                fileUpload" (click)="carregarArquivoPropota(_orcamento.id,
                                                _orcamento.file_proposta_fob)"><span><i
                                                        class="fa
                                                        fa-save"></i><br
                                                        />Subtituir</span>
                                                <input #fileInput
                                                    type="file"
                                                    name="logo"
                                                    id="file"
                                                    class="upload"
                                                    placeholder="Anexar
                                                    proposta"
                                                    (change)="uploadFile(fileInput, 2)">
                                            </button>&nbsp;&nbsp;<button class="btn btn-link" (click)="removeFile(2)">
                                            <i class="fa fa-trash"></i><br />Excluir</button>
                                    </div>
                                </td>
                            </tr>
                            <!-- end anexo  proposta -->


                        </tbody>
                    </table>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-6">
                </div>
                <div class="col-sm-6 text-right">
                    <!-- <button type="submit" class="btn btn-default   Right" (click)="Reload()">Próximo Orcamento</button> -->
                    <button type="submit" *ngIf="_orcamento.tipo == 0" class="btn btn-primary margin-left-10" style="background:
                    #08c65b; border-color: #08c65b;" (click)="ConfirmarEnvioOrcamentoEmail()">
                    <i class="fa fa-check"></i>&nbsp;&nbsp;Enviar Orçamento</button>

                    <button type="submit" *ngIf="_orcamento.tipo == 1" class="btn btn-primary margin-left-10" style="background:
                    #08c65b; border-color: #08c65b;" (click)="ConfirmarEnvioOrcamentoEmail()">
                    <i class="fa fa-check"></i>&nbsp;&nbsp;Salvar e Enviar Orçamento</button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-data-modal [_nome_arquivo]="_nome_arquivo" [_src_arquivo]="_src_arquivo"></app-data-modal>
<app-message-modal [_titulo]="_msg_titulo" [_descricao]="_msg_descricao"></app-message-modal>
<app-entrega-parcial-view [_produto_selecionado]="_produto_selecionado" (_entregas_emit)="ReceiverEntregaProduto($event)"></app-entrega-parcial-view>