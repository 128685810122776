import { OrcamentoProduto } from './orcamento-produto';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { EnumTipoFrete } from '../cotacao/cotacao';
import { ProdutoComprador } from '../suprimento/comprador/produto-comprador';

export class Orcamento {
    public id: number;
    public codigo: string;
    public cotacao_id: number;
    public desconto_linear: string;
    public cnpj: string;
    public user_id: number;
    public status: number;
    public status_por_tipo: number;
    public ha_icms: number;
    public ha_ipi: number;
    public cif: number;
    public fob: number;
    public data_envio: string;
    public data_resposta_comprador: string;
    public valor_total: string;
    public valor_total_fob: string;
    public sub_total: string;
    public data_criacao: string;
    public solicitacao_acesso: number;
    public observacao_solicitante: string;
    public id_usuario_solicitado: number;
    public log: string;
    public origem: string;
    public tipo: number;
    public tipo_frete: number;
    public orcamento_sugerido_id: number;

    public tempo: string;
    public distancia_km: string;
    public distancia_unidade: string;

    public _subtotal: string;
    public _total: string;
    public _total_fob: string;
    public _status: string;
    public _temp_frete: number;
    public codigo_processo_venda: string;

    public preco_frete_fob: string;
    public preco_frete_total_fob: string;
    public _showProcesso: boolean;
    public file_proposta_cif: any;
    public file_proposta_fob: any;
    public cep: string;

    static SetConfig(model: Orcamento, listOrcamentoProduto: OrcamentoProduto[], listAliquota: OrcamentoAliquota[]) {
        var subtotal = 0;
        var total = 0;
        listOrcamentoProduto.forEach(c => {
            if (c._preco_total == "NaN")
                c._preco_total = "0,00"
            if (c.estoque === 0 && c._preco_total)
                subtotal += (UseFullService.ConvertFloat(c._preco_total))
        })
        total = subtotal
        listAliquota.forEach(c => { total += parseFloat(c.valor) })
        model._subtotal = subtotal.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        model._total = total.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        model.valor_total = UseFullService.ReplaceFloat(model._total);
        model.sub_total = UseFullService.ReplaceFloat(model._subtotal);
        model._status = ListOrcamento.GetStatus(model.status)
    }


    static SetConfigFob(model: Orcamento, listOrcamentoProduto: OrcamentoProduto[], listAliquotaFob: OrcamentoAliquota[]) {
        var subtotal = 0;
        var total = 0;
        listOrcamentoProduto.forEach(c => {
            if (c._preco_total == "NaN")
                c._preco_total = "0,00"
            if (c.estoque === 0 && c._preco_total)
                subtotal += (UseFullService.ConvertFloat(c._preco_total))
        })
        total = subtotal
        listAliquotaFob.forEach(c => { total += parseFloat(c.valor) })
        model._total_fob = total.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        model.valor_total_fob = UseFullService.ConvertFloat(model._total_fob);
    }

    static Format(model: Orcamento) {
        model._subtotal = parseFloat(model.sub_total).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        model._total = parseFloat(model.valor_total).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }
}

export class OrcamentoAliquota {
    public id: number;
    public orcamento_id: number;
    public descricao: string;
    public valor: string;

    public _valor: string;
    public _action: number;

    static Set(_aliquotas: OrcamentoAliquota[]) {
        _aliquotas.forEach(c => {
            c._valor = parseFloat(c.valor).toLocaleString('pt-br', { minimumFractionDigits: 2 });
            //parseFloat(c.valor).toFixed(2).toString().replace('.',',');
            //parseFloat(c.valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
            //var f2 = atual.toLocaleString('pt-br', { minimumFractionDigits: 2 });
        });
    }
}

export class OrcamentoSugerido {
    public id: number
    public orcamento_id: number
    public orcamento_historico_id: number
    public orcamento_produto_id: number
    public valor_orcado: string
    public valor_sugerido: string

    public static Config(model: OrcamentoSugerido) {
        model.valor_orcado = UseFullService.ToFixed(model.valor_orcado)
        model.valor_sugerido = UseFullService.ToFixed(model.valor_sugerido)
    }

    public static ObterPorId(list: OrcamentoSugerido[], id): OrcamentoSugerido {
        function ObterPorId(obj: OrcamentoSugerido) {
            return (obj.orcamento_produto_id == id);
        }

        return list.filter(ObterPorId)[0];
    }
}

export class ListOrcamento extends Orcamento {
    public id: number;
    public cotacao_id: number;
    public codigo: string;
    public status: number;
    public cotacao_nome: string;
    public data_envio: string;
    public data_resposta_comprador: string;
    public data_max_recebimento_orcamento: string;
    public valor_total: string;
    public sub_total: string;
    public data_criacao: string;
    public _selected: boolean;
    public _nome_obra: string;
    public _index: number;
    public _status: string;
    public _compra_status: number;
    public _tipo_frete: number;
    public _status_fob: string;
    public _status_cif: string;
    public _valor_total_temp: string;
    public _estoque: number;
    public _aliquota: string;
    public _aliquota_fob: string;
    
    public _produtos: Array<ProdutoComprador>; 
    public _impar = false

    static GetSelected(list: Array<ListOrcamento>): ListOrcamento {
        if (!list)
            return;
        function filtrar(obj) {
            return (obj._selected);
        }
        var model = list.filter(filtrar);
        if (model && model.length > 0)
            return model[0];
        else
            return null;
    }
    static SetConfig(model: ListOrcamento) {
        model.valor_total = (String(model.valor_total).match(',')) ? model.valor_total : UseFullService.ToFixedReal(model.valor_total)
        model.valor_total_fob = (model._temp_frete == 3) ? UseFullService.ToFixedReal(model.valor_total_fob) : model.valor_total;
        
        model._aliquota = (String(model._aliquota).match(',')) ? model._aliquota : UseFullService.ToFixedReal(model._aliquota)
        model._aliquota_fob = (model._temp_frete == 3) ? UseFullService.ToFixedReal(model._aliquota_fob) : model._aliquota_fob;

        model._valor_total_temp = (model.codigo_processo_venda && model.codigo_processo_venda.match('fob') && model._temp_frete == EnumTipoFrete.ciffob) ? model.valor_total_fob : model.valor_total;
        model.sub_total = (String(model.sub_total).match(',')) ? model.sub_total : UseFullService.ToFixedReal(model.sub_total);
        model.data_envio = UseFullService.formatDate(new Date(model.data_envio));
        model.data_resposta_comprador = UseFullService.formatDate(new Date(model.data_resposta_comprador));
        model.data_max_recebimento_orcamento = UseFullService.formatDate(new Date(model.data_max_recebimento_orcamento));
        model.data_criacao = UseFullService.formatDate(new Date(model.data_criacao));
        model._status = ListOrcamento.GetStatus(model.status);
        UseFullService.SetMask();        
    }
    static GetStatus(status: number): string {
        var listStatus = ['Aberto', 'Enviado', 'Aprovado', 'Duplicado', 'Cancelado', 'Expirado'];
        return listStatus[status];
    }
}

export enum EnumStatusOrcamento {
    aberto = 0,
    enviado = 1,
    aprovado = 2,
    canceladoDuplicidade = 3,
    cancelado = 4,
    canceladoData = 5
}

export enum EnumTipoOrcamento {
    comum = 0,
    sugerido = 1
}

export enum EnumStatusTipoOrcamento {
    solicitado = 0,
    reenviado = 1,
    dubplicado = 2
}
