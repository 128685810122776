import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariable } from 'src/app/core/global-variable';
import { Orcamento, ListOrcamento, OrcamentoSugerido } from 'src/app/domain/orcamento/orcamento';
import { Cotacao } from 'src/app/domain/cotacao/cotacao';

@Injectable({
  providedIn: 'root'
})
export class OrcamentoService {
      
  private url = GlobalVariable.BASE_API_URL + "orcamento/";
  constructor(private http: HttpClient) { }
0
  GetAll() {
    return this.http.get<Orcamento>(this.url + "getall").pipe(
      map(res => {
        return res;
      })
    );
  }

  GetAllCNPJ() {
    return this.http.get<ListOrcamento[]>(this.url + "getallCNPJ").pipe(
      map(res => {
        return res;
      })
    );
  }

  Get(id: number) {
    return this.http.get<Orcamento>(this.url + "get?id="+id).pipe(
      map(res => {
        return res;
      })
    );
  }

  GetPaiSugerido(id: number) {
    return this.http.get<Orcamento>(this.url + "get/PaiSugerido?id="+id).pipe(
      map(res => {
        return res;
      })
    );
  }

  GetByQuote(id: number) {
    return this.http.get<Orcamento>(this.url + "getbyquote?cotacao_id="+id).pipe(
      map(res => {
        return res;
      })
    );
  }
  GetByQuoteComprador(id: number) {
    return this.http.get<ListOrcamento[]>(this.url + "getallorcamentocotacao?cotacao_id="+id).pipe(
      map(res => {
        return res;
      })
    );
  }
  GetByQuoteCompradorSuggested(id: number, frete: number) {
    return this.http.get<ListOrcamento>(this.url + "GetByQuoteCompradorSuggested?cotacao_id=" + id + "&frete=" + frete).pipe(
      map(res => {
        return res;
      })
    );
  }
  
  Create(data: Cotacao): Observable<any>{
    return this.http.post<any[]>(this.url+"create", data).pipe(
      map(res=> { return res; })
    );
  }
  
  EnviarPorEmail(data: Cotacao): Observable<any>{
    return this.http.post<any[]>(this.url+"enviarPorEmail", data).pipe(
      map(res=> { return res; })
    );
  }

  CreateEmail(token: string): Observable<Orcamento>{
    return this.http.post<Orcamento>(this.url+"createEmail?token="+token, []).pipe(
      map(res=> { return res; })
    );
  }
  
  Edit(data: Orcamento): Observable<any>{
    return this.http.put<any[]>(this.url+"edit", data).pipe(
      map(res=> { return res; })
    );
  }
  
  Duplicar(orcamentoId: number): Observable<any>{
    return this.http.post<any[]>(this.url+"duplicar?id="+orcamentoId, {}).pipe(
      map(res=> { return res; })
    );
  }
  
  Delete(id: number): Observable<any> {    
    return this.http.delete(this.url+"delete?id="+id).pipe(
      map(res=> { return res; })
    );
  }

  GetOrcamentosFinalizados(){    
    return this.http.get<ListOrcamento[]>(this.url + "getallorcamentocnpj").pipe(
      map(res => {
        return res;
      })
    );
  }
  
  GetAllSugeridosAbertos(){    
    return this.http.get<ListOrcamento[]>(this.url + "getallcnpj/sugerido").pipe(
      map(res => {
        return res;
      })
    );
  }
  
  GetSugerido(id: number){    
    return this.http.get<OrcamentoSugerido[]>(this.url + "get/sugerido?id="+id).pipe(
      map(res => {
        return res;
      })
    );
  }

  GetOrcamentosStatus(status: number){    
    return this.http.get<ListOrcamento[]>(this.url + "getall/status?status="+status).pipe(
      map(res => {
        return res;
      })
    );
  }
  
  public uploadProposta(data: Orcamento){    
    const formData = new FormData();
    formData.append('_file_proposta_cif', data.file_proposta_cif)
    formData.append('_file_proposta_fob', data.file_proposta_fob)
    formData.append('id', String(data.id))
    formData.append('_temp_frete', String(data._temp_frete))
    return this.http.put<Orcamento>(this.url + "proposta/upload", formData).pipe(
      map(res => { return res; })
    )
  }
  
  public removeProposta(data: Orcamento){   
    return this.http.put<Orcamento>(this.url + "proposta/remove", data).pipe(
      map(res => { return res; })
    )
  }
}
