import { Component, OnInit, ComponentFactoryResolver, Input } from '@angular/core';
import { ProdutoCategoriaNivel, ComponetesSelecao, TipoSelecao } from 'src/app/domain/suprimento/produto-categoria-nivel';

import { GlobalVariable } from 'src/app/core/global-variable';
import { Cotacao, EnumTipoFrete } from 'src/app/domain/cotacao/cotacao';
import { EnumStatusOrcamento, EnumStatusTipoOrcamento, EnumTipoOrcamento, Orcamento, OrcamentoAliquota } from 'src/app/domain/orcamento/orcamento';
import { Usuario, TypeUser } from 'src/app/domain/usuario/Usuario';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { OrcamentoFornecedorCtrl } from '../orcamento-forncedor-ctrl';
import { OrcamentoProduto } from 'src/app/domain/orcamento/orcamento-produto';
import { ProdutoComprador } from 'src/app/domain/suprimento/comprador/produto-comprador';
import { List } from 'angular-feather/icons';
import { EnumState } from 'src/app/domain/common/interactiveTable';

declare var $: any;

@Component({
  selector: 'app-orcamento-email-edit',
  templateUrl: './orcamento-email-edit.component.html',
  styleUrls: ['./../orcamento-edit/orcamento-edit.component.css']
})
export class OrcamentoEmailEditComponent extends OrcamentoFornecedorCtrl implements OnInit {
  _orcamento_criado = false
  _msg_titulo = ''
  _msg_descricao = ''

  _cotacao_id = 0
  ngOnInit() {
    this.confirmService.UnsetObservers();
    this.confirmService.confirmEmitter$.subscribe(c => { this.DeleteAliquota(c); this.FinalizarOrcamentoEmail(c); this.OrcamentoUpdate(c); });

    this.userLoggedService.SetCurrentUser();
    this._niveis = new Array<ProdutoCategoriaNivel>();
    this.VerificaOrcamento()
  }
  ReceiverEntregaProduto(e) {

  }

  VerificaOrcamento() {
    this.orcamentoService.CreateEmail(this.userLoggedService.urlToken).subscribe(
      d => {
        this._orcamento_criado = true;
        this._cotacao_id = d.cotacao_id
        this._orcamento = d;
        if (d.status != EnumStatusOrcamento.aberto) {
          if (d.tipo == EnumTipoOrcamento.sugerido) { 
            if(d.status > EnumStatusOrcamento.enviado || d.status_por_tipo == EnumStatusTipoOrcamento.reenviado)
              this.router.navigateByUrl('/orcamentos/email/view');
          }else if (d.tipo == EnumTipoOrcamento.comum)
            this.router.navigateByUrl('/orcamentos/email/view')
        }

        if (this.userLoggedService.type == TypeUser.provider)
          this.router.navigateByUrl('/orcamentos/edit/' + d.id);
        else {
          this.SetListaUnidadeMedidaEmail()
          this.GetEmpresaLoggedIn()
        }
      },
      e => { this.httpAuthenticate.logout(); });
  }

  GetCotacaoEmail(id: number) {
    this.cotacaoService.Get(id).subscribe(
      d => { this._cotacao = d; },
      e => { },
      () => {
        this._cotacao._meio_pagamento = Cotacao.GetMeioPagamento(this._cotacao.meio_pagamento);
        Cotacao.SetFormat(this._cotacao);
        this.GetEndereco(this._cotacao.endereco_id);
        this.GetAllProdutos(this._cotacao_id);
        this.CarregarAliquota(this._orcamento.id);
      }
    );
  }

  SetListaUnidadeMedidaEmail() {
    this.httpProduto.GetAllUnitsMeasured().subscribe(
      data => { this._unidade_medida = data; },
      error => { },
      () => {
        this.GetCotacaoEmail(this._cotacao_id);
      }
    );
  }

  ConfirmarEnvioOrcamentoEmail() {

    if (this._orcamento.tipo === EnumTipoOrcamento.sugerido) {
      this.AtualizarOrcamento();
      return;
    }

    this.ValidarItens()
    /*
        if (!this._li_termos)
          throw ("Leia os termos para enviar o orçamento!")
    */
    this.confirmService._code = this._code_right
    this.confirmService._title = "Enviar Orçamento"
    this.confirmService._message = "Confirma o envio?"
    this.confirmService._message_input_geral = " <b>Insira o CNPJ:</b>"
    this.confirmService._message_input_secundario = " <b>Insira o CEP:</b>"
    this.confirmService._require = 'cnpj'
    this.confirmService._placeholder = "__.___.___/____-__"
    setTimeout(() => {
      $(".input-geral").mask("99.999.999/9999-99")
      $(".input-secundario").mask("99999-999")
    }, 1000)
    if (UseFullService.IsCNPJ(this._orcamento.cnpj))
      this.confirmService._input = this._orcamento.cnpj
    this.confirmService._input_secundario = this._orcamento.cep
    this.confirmService.Show(true)
  }

  FinalizarOrcamentoEmail(confirm: boolean) {
    if (!this._orcamento || !confirm || this.confirmService._code != this._code_right)
      return;
    this.VerificaCnpj()
  }

  VerificaCnpj() {
    var _this = this
    this._orcamento.cnpj = this.confirmService._input
    this._orcamento.cep = this.confirmService._input_secundario
    this.userService.getAllUserCNPJ(this._orcamento.cnpj).subscribe(
      d => {
        if (d && d != null && d.length > 0) {
          setTimeout(() => {
            this._msg_titulo = 'CNPJ Já Cadastrado'
            this._msg_descricao = 'Por questões de segurança o orçamento não pode ser enviado, sua empresa já contém um cadastro. '
            this._msg_descricao += ' Solicite seu acesso a um administrador do sistema, para enviar o orçamento.'
            this._msg_descricao += '<br /><br /><b>Sugestão de contato</b>'
            this._msg_descricao += '<br /><b>CNPJ:</b> ' + d[0].cnpj
            this._msg_descricao += '<br /><b>Usuario:</b> ' + d[0].nome
            this._msg_descricao += '<br /><b>E-mail:</b> ' + d[0].email
            this._msg_descricao += '<br /><br /><b>Deseja enviar ao administrador solicitação de acesso? </b>'
            this._msg_descricao += '<div class="confirm-message-email">'
            if (this._orcamento.solicitacao_acesso < 1) {
              this._msg_descricao += '<label class="btn btn-secondary">Não</label><label class="btn btn-primary" >Sim</label></div>'
            } else
              this._msg_descricao += '<label class="text-success">Solicitação acesso já foi enviada!</label></div>'

            this._msg_descricao += '<br /><br />Caso possua uma conta no painel da engenharia. <a href="' + GlobalVariable.HOST + '#/login/email/' + this.userLoggedService.urlToken + '">clique aqui!</a>'
            this._msg_descricao += '<br /><br />Para mais informações e suporte acesse: <a href="https://www.paineldaengenharia.com.br/fornecedor">www.paineldaengenharia.com.br</a>'
            $('#message-modal').modal('show')

            setTimeout(() => {
              $('.confirm-message-email textarea').remove()
              const disabled = (this._orcamento.solicitacao_acesso > 0) ? 'disabled' : ''
              const text = (this._orcamento.observacao_solicitante) ? this._orcamento.observacao_solicitante : ''
              $('.confirm-message-email').append('<textarea class="form-control   " ' + disabled + ' placeholder="Digite algo que ajude o administrador te identificar" rows="3">' + text + '</textarea>')
              $('.confirm-message-email > label').click((e) => {
                if ($(e.currentTarget).attr('class').match('btn'))
                  _this.SolicitarAcesso($(e.currentTarget).attr('class').match('btn-primary'), d[0].id)
              });
            }, 500)
          }, 500);
        } else
          this.EnviarOrcamentoEmail()
      }
    )
  }

  EnviarOrcamentoEmail() {
    this._orcamento.status = EnumStatusOrcamento.enviado
    this._orcamento.data_envio = UseFullService.formatDateTimeUS(new Date)
    if (this.userLoggedService.type == TypeUser.provider)
      this._orcamento.user_id = this.userLoggedService.id
    this._orcamento.valor_total = UseFullService.ReplaceFloat(this._orcamento._total)
    this._orcamento.sub_total = UseFullService.ReplaceFloat(this._orcamento._subtotal)
    this.orcamentoService.Edit(this._orcamento).subscribe(d => {
      this.httpAuthenticate.loginEmail(this.userLoggedService.urlToken).subscribe(
        data => { },
        error => { },
        () => {
          if (this.httpAuthenticate.isLoggedIn())
            this.router.navigateByUrl('/orcamentos/email/view')
        }
      )
    })
  }

  SolicitarAcesso(solicitacao: boolean, usuarioId: number) {
    if (solicitacao) {
      this._orcamento.id_usuario_solicitado = usuarioId
      this._orcamento.observacao_solicitante = $('.confirm-message-email textarea').val()
      this._orcamento.solicitacao_acesso = 1
      this._orcamento.valor_total = UseFullService.ReplaceFloat(this._orcamento._total)
      this._orcamento.sub_total = UseFullService.ReplaceFloat(this._orcamento._subtotal)
      this.orcamentoService.Edit(this._orcamento).subscribe(d => {
        $('.confirm-message-email').html('<label class="text-success">Solicitação enviada com sucesso!</label>')
      })
    } else
      $('#message-modal').modal('hide')
  }


  /********* orcamento sugerido *********/

  OnChangeProductTemp(orcamento_produto, produtoId) {
    setTimeout(() => {

      if (this._orcamento.tipo === EnumTipoOrcamento.comum)
        this.OnChangeProduct(orcamento_produto, produtoId)
      else {
        orcamento_produto.icms = orcamento_produto._icms;
        orcamento_produto.ipi = orcamento_produto._ipi;
        orcamento_produto.preco = orcamento_produto._preco;

        OrcamentoProduto.SetConfig(orcamento_produto, this._list_produtos, this._orcamento);
        Orcamento.SetConfig(this._orcamento, this._orcamento_produtos, this._aliquotas);
        if (this._cotacao.tipo_frete == EnumTipoFrete.ciffob)
          Orcamento.SetConfigFob(this._orcamento, this._orcamento_produtos, this._aliquotas_fob);
      }

    }, 10);
  }

  ClickCheckedTemp(produto: ProdutoComprador, e) {

    if (this._orcamento.tipo === EnumTipoOrcamento.comum)
      this.ClickChecked(produto._orcamento_produto, e);
    else {
      produto._orcamento_produto.estoque = (e.srcElement.checked) ? 0 : 1;
      this.OnChangeProductTemp(produto._orcamento_produto, produto.id);
    }
  }

  async ClickCheckedIcmsTemp(orcamento: Orcamento, e) {

    if (this._orcamento.tipo === EnumTipoOrcamento.comum)
      this.ClickCheckedIcms(orcamento, e)
    else {
      orcamento.ha_icms = (e.srcElement.checked) ? 0 : 1;
      if (orcamento.ha_icms) {
        this._list_produtos.forEach(c => {
          c._orcamento_produto._icms = 0;
          this.OnChangeProductTemp(c._orcamento_produto, c.id);
        });
      }
    }
  }

  async ClickCheckedIpiTemp(orcamento: Orcamento, e) {
    if (this._orcamento.tipo === EnumTipoOrcamento.comum)
      this.ClickCheckedIpi(orcamento, e)
    else {
      orcamento.ha_ipi = (e.srcElement.checked) ? 0 : 1;
      if (orcamento.ha_ipi)
        this._list_produtos.forEach(c => {
          c._orcamento_produto._ipi = 0;
          this.OnChangeProductTemp(c._orcamento_produto, c.id);
        });
    }
  }

  AtualizarOrcamento() {
    this.confirmService._code = "orcamento_sugerido_update"
    this.confirmService._title = "Atualizar e Reeviar Orçamento"
    this.confirmService._message = "<b>CNPJ: </b>" + this._orcamento.cnpj + " <br /><b>CEP: </b>" + this._orcamento.cep + " <br /><br /> Confirma o Reenvio?"
    this.confirmService.Show(true)
  }

  OrcamentoUpdate(confirm: boolean) {
    if (!confirm || this.confirmService._code != "orcamento_sugerido_update")
      return;

    const qtdProduto = this._list_produtos.length
    for (let i = 0; i < qtdProduto; i++) {
      const data = this._list_produtos[i]._orcamento_produto
      this.orcamentoProdutoService.Edit(data).subscribe();
    }
    this._orcamento.status_por_tipo = EnumStatusTipoOrcamento.reenviado
    this.AtualizaAliquotasTemp()
    this.OnChangeOrcamentoPreco();
    setTimeout(() => { document.location.reload(); }, 2000);
  }


  AdicionarAliquotaCifTemp(aliquota: OrcamentoAliquota) {

    if (this._orcamento.tipo === EnumTipoOrcamento.comum) {
      this.AdicionarAliquota(aliquota);
      return;
    }

    aliquota.orcamento_id = this._orcamento.id;
    aliquota._action = EnumState.insert
    this._listAliquotasCif.push(aliquota)
    this._aliquotas.push(aliquota)
    this._aliquota = new OrcamentoAliquota();
    OrcamentoAliquota.Set(this._aliquotas);
    setTimeout(() => {
      Orcamento.SetConfig(this._orcamento, this._orcamento_produtos, this._aliquotas);
    }, 10);
  }

  AdicionarAliquotaFobTemp(aliquota: OrcamentoAliquota) {

    if (this._orcamento.tipo === EnumTipoOrcamento.comum) {
      this.AdicionarAliquotaFob(aliquota);
      return;
    }
    aliquota.orcamento_id = this._orcamento.id;
    aliquota._action = EnumState.insert
    this._listAliquotasFob.push(aliquota)
    this._aliquotas_fob.push(aliquota)
    this._aliquotaFob = new OrcamentoAliquota();
    OrcamentoAliquota.Set(this._aliquotas_fob);
    setTimeout(() => {
      Orcamento.SetConfig(this._orcamento, this._orcamento_produtos, this._aliquotas_fob);
    }, 10);
  }




  _listAliquotasCif = new Array<OrcamentoAliquota>();
  _listAliquotasFob = new Array<OrcamentoAliquota>();
  DeleteAliquotaFobTemp(aliquota: OrcamentoAliquota) {
    if (this._orcamento.tipo === EnumTipoOrcamento.comum) {
      this.ConfirmeRemoveAliquotaFob(aliquota);
      return;
    }
    for (let i = 0; i < this._aliquotas_fob.length; i++) {
      const e = this._aliquotas_fob[i];
      if (e.id == aliquota.id) {
        aliquota._action = EnumState.delete
        this._listAliquotasFob.push(aliquota)
        this._aliquotas_fob.splice(i, 1)
        continue;
      }
    }
    OrcamentoAliquota.Set(this._aliquotas_fob);
    setTimeout(() => {
      Orcamento.SetConfig(this._orcamento, this._orcamento_produtos, this._aliquotas_fob);
    }, 10);
  }

  DeleteAliquotaCifTemp(aliquota: OrcamentoAliquota) {
    if (this._orcamento.tipo === EnumTipoOrcamento.comum) {
      this.ConfirmeRemoveAliquota(aliquota);
      return;
    }
    for (let i = 0; i < this._aliquotas.length; i++) {
      const e = this._aliquotas[i];
      if (e.id == aliquota.id) {
        aliquota._action = EnumState.delete
        this._listAliquotasCif.push(aliquota)
        this._aliquotas.splice(i, 1)
        continue;
      }
    }
    OrcamentoAliquota.Set(this._aliquotas);
    setTimeout(() => {
      Orcamento.SetConfig(this._orcamento, this._orcamento_produtos, this._aliquotas);
    }, 10);
  }


  AtualizaAliquotasTemp() {
    for (let i = 0; i < this._listAliquotasCif.length; i++) {
      var e = this._listAliquotasCif[i]
      if (e._action === EnumState.delete && e.id > 0)
        this.aliquotaService.Delete(e.id).subscribe();
      else if (e._action === EnumState.insert && !e.id)
        this.aliquotaService.Create(e).subscribe()
    }
    for (let i = 0; i < this._listAliquotasFob.length; i++) {
      var e = this._listAliquotasFob[i];
      if (e._action === EnumState.delete && e.id > 0)
        this.aliquotaService.DeleteFOB(e.id).subscribe();
      else if (e._action === EnumState.insert && !e.id)
        this.aliquotaService.CreateFOB(e).subscribe()
    }
  }
}