<div class="col-lg-12 wrapper-top-content">


    <div class="ibox">
        <div class="ibox-title" style="padding-right:20px;">
            <h5>Orçamentos Recebidos</h5>
            <div style="float: right;"></div>
        </div>


        <div class="ibox-content">

            <h4>Dados da Cotação</h4>
            <b>Codigo:&nbsp;&nbsp;</b>{{_cotacao.codigo}} <br />
            <b>Nome:&nbsp;&nbsp;</b>{{_cotacao.nome}} <br />
            <b>Obra:&nbsp;&nbsp;</b>{{_cotacao.nome_obra}}<br />

            <span class="margin-right-20"><b>Data:</b>&nbsp;&nbsp;{{_cotacao._data_criacao}}</span>&nbsp;&nbsp;&nbsp;
            <span class="margin-right-20"><b>Vencimento:</b>&nbsp;&nbsp;{{_cotacao._data_max_recebimento_orcamento}}</span>&nbsp;&nbsp;&nbsp;
            <span *ngIf="(_cotacao.recebimento_produto != 2)"><b>Entrega:</b>&nbsp;&nbsp;{{_cotacao._data_recebimento_produto}}</span>

            <div class="row">

                <nz-table class="table" #columnTable [nzData]="listGrid" [nzScroll]="{ x:
                    '1100px' }" [nzFrontPagination]="false">
                    <thead class="thead-dark">
                        <tr>
                            <th nzLeft class="badge-white no-border" style="width: 100px; min-width: 100px;"></th>
                            <th nzLeft class="badge-white no-border" style="width: 400px; min-width: 400px;"></th>
                            <th nzLeft class="badge-white no-border" style="width: 120px; min-width: 120px;"></th>
                            <th nzLeft class="badge-white no-border" style="width: 120px; min-width: 120px;"></th>
                            <th *ngFor="let o of _list_orcamento" class="text-center width-td-250
                                {{(o._impar)?'bg-impar':''}}" style="width:
                                300px;">n°: <b>{{o.codigo}}</b></th>
                            <!--
                            <th class="text-center" colspan="2" style="width: 300px; background:#f3f3f3">n°: <b>PE5792873</b></th>
                            <th class="text-center" colspan="2" style="width: 300px;">n°: <b>PE5792873</b></th>
                            <th class="text-center" colspan="2" style="width: 300px; background:#f3f3f3">n°: <b>PE5792873</b></th>
                            <th class="text-center" colspan="2" style="width: 300px;">n°: <b>PE5792873</b></th>
                            -->
                        </tr>
                        <tr>
                            <th nzLeft class="text-center badge-dark">Orçamento</th>
                            <th nzLeft class="text-center badge-dark">Insumo</th>
                            <th nzLeft class="text-center badge-dark">Unidade</th>
                            <th nzLeft class="text-center badge-dark">QTD</th>
                            <th *ngFor="let o of _list_orcamento" class="text-right badge-dark">
                                <div class="div-td-2">Unt R$</div>
                                <div class="div-td-2">Total R$</div>
                            </th>
                        </tr>
                    </thead>
                    <!--{{o._produtos[x._index - 1].estoque > 0 ? 'n-disabled' : ''}}-->
                    <tbody>
                        <tr *ngFor="let x of produtos">
                            <td nzLeft class="text-center badge-white
                                align-middle">{{x._index}}</td>
                            <td nzLeft class="badge-white align-middle">{{x.nome}}</td>
                            <td nzLeft class="text-center badge-white
                                align-middle">{{x._unidade}}</td>
                            <td nzLeft class="text-center badge-white
                                align-middle">{{x.quantidade}}</td>
                            <td *ngFor="let o of _list_orcamento" class="text-right align-middle
                                {{(o._impar)?'bg-impar':''}} {{o._produtos &&
                                o._produtos[x._index -
                                1]._orcamento_produto.estoque> 0 ? 'n-disabled'
                                : ''}}
                                {{o._produtos && o._produtos[x._index -
                                1]._orcamento_produto._preco_string}} ">
                                <div class="div-td-2" *ngIf="o._produtos">{{o._produtos[x._index - 1]._orcamento_produto._preco_string}}</div>
                                <div class="div-td-2" *ngIf="o._produtos">{{o._produtos[x._index - 1]._orcamento_produto._preco_total}}</div>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot class="tfoot-border">

                        <tr *ngIf="_cotacao.tipo_frete === 3 ||
                            _cotacao.tipo_frete === 1">
                            <td nzLeft class="badge-white align-middle
                                text-center" rowspan="2" colspan="4">


                                <div class="row">


                                    <div class="col-sm-5 text-left" *ngIf="(_orcamento_sugerido_cif.id> 0)">

                                        <div class="card text-white bg-primary mb-2" style="margin-left: 15px; float: left; width: 100%;">
                                            <div class="card-header text-center">
                                                <b>Pedido De Desconto</b>
                                            </div>
                                            <div class="card-body cursor-pointer card-body-hover
                                            animated" (click)="VerSugerido(_orcamento_sugerido_cif, 'cif')">
                                                <div class="text-center" style="height: 76px;" *ngIf="!(_orcamento_sugerido_cif.id > 0)">
                                                    <label style="padding-top: 27px;">
                                                    <span *ngIf="(!_cotacao._expirado)">
                                                        <i class="fa fa-clock"></i>&nbsp;&nbsp;
                                                        Em Andamento
                                                    </span>
                                                </label>
                                                </div>
                                                <p class="card-text" *ngIf="(_orcamento_sugerido_cif.id > 0)">
                                                    <b>Subtotal: &nbsp;&nbsp; </b>R$ {{_orcamento_sugerido_cif.sub_total}} <br />
                                                    <b>Valor Total: &nbsp;&nbsp;</b> R$ {{_orcamento_sugerido_cif.valor_total}} <br />
                                                    <b>Orçamento: &nbsp;&nbsp;</b> {{(_orcamento_sugerido_cif.status == 1)? (_cotacao._expirado)?'Expirado':'Aguardando Compra':'Orçamento Aprovado'}}
                                                    <br />
                                                    <b>Status do Pedido: &nbsp;&nbsp;</b> {{(_orcamento_sugerido_cif.status_por_tipo == 1)? 'Respondido':'Enviada'}}<br />
                                                    <br *ngIf="(_orcamento_sugerido_cif.status <2)" />
                                                    <b *ngIf="(_orcamento_sugerido_cif.status == 2)">Venda:
                                                    &nbsp;&nbsp;</b>
                                                    <span *ngIf="(_orcamento_sugerido_cif.status == 2)">
                                                    {{(_orcamento_sugerido_cif._compra_status ==
                                                    0)?'Aguardando
                                                    Fornecedor':(_orcamento_sugerido_cif._compra_status
                                                    == 2)
                                                    ?'Recusada':'Aprovada'}}
                                                </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-7 text-center">
                                        <div style="margin-bottom: 15px;"><b>Entrega - CIF</b></div>
                                        <button type="button" class="btn btn-default" (click)="MelhorOrcamento(1)" data-toggle="modal" data-target="#modal-cif-fob">Melhores Preços
                                            Por Item - CIF</button><br />
                                    </div>

                                    <div class="col-sm-5 text-center" *ngIf="!(_orcamento_sugerido_cif.id> 0)">
                                        <button type="button" class="btn btn-primary" (click)="realizarBoot(1)">
                                            Realizar Negociação
                                        </button>
                                    </div>

                                </div>
                            </td>

                            <td *ngFor="let o of _list_orcamento" class="text-right {{(o._impar)?'bg-impar':''}}
                                link-td-cif {{o.cif> 0 ? 'n-disabled' : ''}}" (click)="Ver(o, 'cif')">
                                <div class="div-td-2 text-left"><b>Alíquota/Frete</b></div>
                                <div class="div-td-2">{{o._aliquota}}</div>
                                <br /><br />
                                <div class="div-td-2 text-left"><b>Valor Total</b></div>
                                <div class="div-td-2">{{o.valor_total}}</div>
                                <br /><br />
                                <div class="div-td-2 text-left"><b>Situação</b></div>
                                <div class="div-td-2">{{o._status_cif}}</div>
                            </td>
                        </tr>

                        <tr *ngIf="_cotacao.tipo_frete === 3 ||
                            _cotacao.tipo_frete === 1">
                            <td *ngFor="let o of _list_orcamento" class="text-right
                                link-td-cif {{o.cif> 0 ? 'n-disabled' : ''}}
                                background-info" (click)="carregarArquivoPropota(o,
                                o.file_proposta_cif)">
                                <div class="div-td-2 text-left"><b>Proposta</b></div>
                                <div class="div-td-2 text-right">
                                    <button class="btn btn-link limit-string">
                                        {{o.file_proposta_cif}}</button>
                                </div>
                            </td>
                        </tr>

                        <tr *ngIf="_cotacao.tipo_frete === 3 ||
                            _cotacao.tipo_frete === 2">
                            <td nzLeft class="badge-white align-middle
                                text-center" rowspan="5" colspan="4">
                                <div class="row">
                                    <div class="col-sm-5 text-left" *ngIf="(_orcamento_sugerido_fob.id> 0)">
                                        <div class="card text-white bg-primary mb-2" style="margin-left: 15px; float: left; width: 100%; box-shadow: 0px 0px 10px 3px #ececec;">
                                            <div class="card-header">
                                                <b>Orçamento Negociado</b>
                                            </div>
                                            <div class="card-body cursor-pointer card-body-hover
                                            animated" (click)="VerSugerido(_orcamento_sugerido_fob, 'fob')">
                                                <div class="text-center" style="height: 76px;" *ngIf="!(_orcamento_sugerido_fob.id> 0)">
                                                    <label style="padding-top: 27px;">
                                                    <span *ngIf="(!_cotacao._expirado)">
                                                        <i class="fa fa-clock"></i>&nbsp;&nbsp;Em Andamento
                                                    </span>
                                                </label>
                                                </div>
                                                <p class="card-text" *ngIf="(_orcamento_sugerido_fob.id > 0)">
                                                    <b>Subtotal: &nbsp;&nbsp; </b>R$ {{_orcamento_sugerido_fob.sub_total}} <br />
                                                    <b>Valor Total: &nbsp;&nbsp;</b> R$ {{_orcamento_sugerido_fob.valor_total_fob}} <br />


                                                    <b>Orçamento: &nbsp;&nbsp;</b> {{(_orcamento_sugerido_fob.status == 1)? (_cotacao._expirado)?'Expirado':'Aguardando Compra':'Orçamento Aprovado'}}
                                                    <br />
                                                    <b>Status do Pedido: &nbsp;&nbsp;</b> {{(_orcamento_sugerido_fob.status_por_tipo == 1)? 'Respondido':'Enviado'}}<br />

                                                    <br *ngIf="(_orcamento_sugerido_fob.status <2)" />
                                                    <b *ngIf="(_orcamento_sugerido_fob.status == 2)">Venda:
                                                        &nbsp;&nbsp;</b>
                                                    <span *ngIf="(_orcamento_sugerido_fob.status == 2)">
                                                        {{(_orcamento_sugerido_fob._compra_status == 0)?'Aguardando
                                                        Fornecedor':(_orcamento_sugerido_fob._compra_status == 2)?'Recusada':'Aprovada'}}
                                                    </span>
                                                </p>

                                            </div>
                                        </div>

                                    </div>

                                    <div class="col-sm-7 text-center">
                                        <b>Entrega - FOB</b>
                                        <div class="preco-km">
                                            <label class="">Custo por KM (R$):</label>
                                            <!--{ prefix: 'R$ ', thousands: '.', decimal: ',' }-->
                                            <input currencyMask [options]="{
                                                prefix: 'R$ ', thousands: '.',
                                                decimal: ',' }" class="form-control money" (ngModelChange)="OnChangePrecoPorKm()" [(ngModel)]="_cotacao.preco_por_km" type="text" placeholder="0,00" [disabled]="(_orcamento_aprovado.id)">
                                        </div>
                                        <button type="button" class="btn
                                            btn-default" (click)="MelhorOrcamento(2)" data-toggle="modal" data-target="#modal-cif-fob">Melhores
                                            Preços
                                            Por Item - FOB</button><br />
                                    </div>

                                    <div class="col-sm-5 text-center" *ngIf="!(_orcamento_sugerido_fob.id > 0)">
                                        <button type="button" class="btn btn-primary" (click)="realizarBoot(2)">
                                            Realizar Negociação
                                        </button>
                                    </div>

                                </div>

                            </td>
                            <td *ngFor="let o of _list_orcamento" class="text-right {{(o._impar)?'bg-impar':''}}
                                link-td-fob {{o.fob> 0 ? 'n-disabled' : ''}}" (click)=" Ver(o, 'fob') ">
                                <div class="div-td-2 text-left"><b>Custa FOB</b></div>
                                <div class="div-td-2">R$ {{o.preco_frete_fob}}</div>
                                <br /><br />
                                <div class="div-td-2 text-left"><b>Distância</b></div>
                                <div class="div-td-2">{{o.distancia_km}} {{o.distancia_unidade}}
                                </div>
                                <br /><br />
                                <div class="div-td-2 text-left"><b>Tempo Percurso</b></div>
                                <div class="div-td-2">{{o.tempo}}</div>
                                <br /><br />
                                <div class="div-td-2 text-left"><b>Alíquota</b></div>
                                <div class="div-td-2">R$ {{o._aliquota_fob}}</div>
                                <br /><br />
                                <div class="div-td-2 text-left"><b>Valor Total</b></div>
                                <div class="div-td-2">R$ {{o.preco_frete_total_fob}}
                                </div>
                                <br /><br />
                                <div class="div-td-2 text-left"><b>Situação</b></div>
                                <div class="div-td-2">{{o._status_fob}}</div>
                            </td>
                        </tr>

                        <tr *ngIf="_cotacao.tipo_frete === 3 ||
                            _cotacao.tipo_frete === 2">
                            <td *ngFor="let o of _list_orcamento" class="text-right
                                link-td-fob background-info" (click)="carregarArquivoPropota(o,
                                o.file_proposta_fob)">
                                <div class="div-td-2 text-left"><b>Proposta</b></div>
                                <div class="div-td-2">
                                    <a class="btn btn-link limit-string">{{o.file_proposta_fob}}</a>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="badge-white no-border" colspan="12"></td>
                        </tr>
                    </tfoot>
                </nz-table>


            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modal-cif-fob" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Melhor Preço Por Insumo - {{_cif_fob_modal}}
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="padding: 0px;">
                <table class="table table-striped">
                    <thead class="thead-dark">
                        <tr>
                            <th class="text-center">Orçamento</th>
                            <th>Insumo</th>
                            <th class="text-center">Unidade</th>
                            <th class="text-center">QTD</th>
                            <th class="text-right">Valor Unt R$</th>
                            <th class="text-right">Valor Total R$</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let x of listGrid">
                            <td class="text-center">{{x._codigo}}</td>
                            <td>{{x.nome}}</td>
                            <td class="text-center">{{x._unidade}}</td>
                            <td class="text-center">{{x.quantidade}}</td>
                            <td class="text-right">{{x.preco}}</td>
                            <td class="text-right">{{x.precoTotal}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="4"></td>
                            <td class="text-right"><b>Valor Total</b></td>
                            <td class="text-right">{{_precoTotalModal}}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>


<app-orcamento-comprador-modal (gridLoad)="ReceiverReload($event)" [_list_orcamento]="_list_orcamento" [_grid_tipo_frete]="_grid_tipo_frete"></app-orcamento-comprador-modal>
<app-data-modal [_nome_arquivo]="_nome_arquivo" [_src_arquivo]="_src_arquivo"></app-data-modal>