import { Component, OnInit, ComponentFactoryResolver, Input } from '@angular/core';
import { OrcamentoFornecedorCtrl } from '../orcamento-forncedor-ctrl';
import { EnumStatusOrcamento, EnumStatusTipoOrcamento, EnumTipoOrcamento } from 'src/app/domain/orcamento/orcamento';
import { ProdutoCategoriaNivel } from 'src/app/domain/suprimento/produto-categoria-nivel';
import { TypeUser } from 'src/app/domain/usuario/Usuario';
import { Cotacao } from 'src/app/domain/cotacao/cotacao';
import { GlobalVariable } from 'src/app/core/global-variable';


declare var $: any;

@Component({
  selector: 'app-orcamento-email-view',
  templateUrl: './../orcamento-fornecedor-view/orcamento-fornecedor-view.component.html',
  styleUrls: ['./../orcamento-fornecedor-view/orcamento-fornecedor-view.component.css']
})
export class OrcamentoEmailViewComponent extends OrcamentoFornecedorCtrl implements OnInit {
  origem_email = true
  _code = 'edit-orcamento-fornecedor-view'
  _cotacao_id = 0
  ngOnInit() {
    this.confirmService.UnsetObservers();
    this.confirmService.confirmEmitter$.subscribe(c => { this.EditBudget(c); });

    this.userLoggedService.SetCurrentUser();
    this._niveis = new Array<ProdutoCategoriaNivel>();
    this.VerificaOrcamento()
  }

  ReceiverEntregaProduto(e) {

  }

  VerificaOrcamento() {
    this.orcamentoService.CreateEmail(this.userLoggedService.urlToken).subscribe(
      d => {
        this._cotacao_id = d.cotacao_id
        this._orcamento = d;
        if (this._orcamento.tipo == EnumTipoOrcamento.sugerido && this._orcamento.status <= EnumStatusOrcamento.enviado &&
          this._orcamento.status_por_tipo == EnumStatusTipoOrcamento.solicitado)
          this.router.navigateByUrl('/orcamentos/email/edit');
        this.SetListaUnidadeMedidaEmail()
        this.GetEmpresaLoggedIn()
      },
      e => { this.httpAuthenticate.logout(); });
  }

  _status_orcamento = '';
  GetCotacaoEmail(id: number) {
    this.cotacaoService.Get(id).subscribe(
      d => { this._cotacao = d; },
      e => { },
      () => {
        this._cotacao._meio_pagamento = Cotacao.GetMeioPagamento(this._cotacao.meio_pagamento);
        Cotacao.SetFormat(this._cotacao);
        this.GetEndereco(this._cotacao.endereco_id);

        this.SetModal();
        this._status_orcamento = (this._orcamento.status == 1) ? 'Aguardando Resposta Comprador' : (this._orcamento.status == 2) ? 'Orçamento APROVADO' : 'Orçamento Reprovado'
        this.GetAllProdutos(this._cotacao_id);
        this.CarregarAliquota(this._orcamento.id);

      }
    );
  }

  SetListaUnidadeMedidaEmail() {
    this.httpProduto.GetAllUnitsMeasured().subscribe(
      data => { this._unidade_medida = data; },
      error => { },
      () => {
        this.GetCotacaoEmail(this._cotacao_id);
      }
    );
  }


  ConfirmeEdit() {
    this.confirmService._code = this._code;
    this.confirmService._title = "Reabrir Orçamento";
    this.confirmService._message = "Você desea reabrir orçamento para edição? Clique em <b>SIM</b> para confirmar.";
    this.confirmService.Show();
  }

  EditBudget(confirm: boolean) {
    if (!this._orcamento || !confirm || this.confirmService._code != this._code)
      return;

    if (this._orcamento.tipo == EnumTipoOrcamento.sugerido)
      this._orcamento.status_por_tipo = EnumStatusTipoOrcamento.solicitado
    else
      this._orcamento.status = EnumStatusOrcamento.aberto

    this._orcamento.valor_total = this._orcamento._total.replace('.', '').replace(',', '.')
    this._orcamento.sub_total = this._orcamento._subtotal.replace('.', '').replace(',', '.')
    this.orcamentoService.Edit(this._orcamento).subscribe(
      d => { },
      e => { this._orcamento.status = EnumStatusOrcamento.enviado },
      () => { this.router.navigateByUrl('/orcamentos/email/edit'); }
    );
  }

  _msg_titulo = ''
  _msg_descricao = ''
  SetModal() {
    if (this._orcamento.status == EnumStatusOrcamento.aprovado) {
      this._msg_titulo = '<label class="titulo-msg">Orçamento <b class="text-success" >APROVADO&nbsp;&nbsp; <i class="fa fa-check"></i></b></label>'
      this._msg_descricao = '<label class="text-dark body-msg" >O cliente aprovou o seu orçamento. <br /> Por motivos de segurança '
      this._msg_descricao += ' é necessário efetuar o cadastro da sua empresa.</label>'
      this._msg_descricao += '<div class="msg-nova-conta"><a class="btn btn-success" target="black" href="https://www.paineldaengenharia.com.br/gestao/fornecedor/cadastro-fornecedor">Cadastrar</a><br />'
      this._msg_descricao += 'Caso possua uma conta, <a href="' + GlobalVariable.HOST + '/#/login/email/' + this.userLoggedService.urlToken + '">clique aqui!</a></div>'
      $('#message-modal').modal('show')
    }
  }
}