import { Component, OnInit, ComponentFactoryResolver, Input } from '@angular/core';
import { OrcamentoFornecedorCtrl } from '../orcamento-forncedor-ctrl';
import { EnumStatusOrcamento } from 'src/app/domain/orcamento/orcamento';

declare var $: any;

@Component({
  selector: 'app-orcamento-fornecedor-view',
  templateUrl: './orcamento-fornecedor-view.component.html',
  styleUrls: ['./orcamento-fornecedor-view.component.css']
})
export class OrcamentoFornecedorViewComponent extends OrcamentoFornecedorCtrl implements OnInit {
  origem_email = false;
  ConfirmeEdit() {
    this._orcamento.status = EnumStatusOrcamento.aberto;
    this._code_dinamic = 'edit-orcamento-fornecedor-view';
    this.confirmService._code = this._code_dinamic;
    this.confirmService._title = "Reabrir Orçamento";
    this.confirmService._message = "Você deseja reabrir orçamento para edição? Clique em <b>SIM</b> para confirmar.";
    this.confirmService.Show();
  }  
  ReceiverEntregaProduto(e){}
}