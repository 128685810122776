
import { Component, OnInit, ComponentFactoryResolver, Input } from '@angular/core';
import { ProdutoService } from '../../../../service/suprimento/produto.service';
import { Produto, EspecificacaoProduto, NomeProduto } from '../../../../domain/suprimento/produto';
import { ProdutoCategoria } from 'src/app/domain/suprimento/produto-categoria';
import { ProdutoUnidadeMedida } from 'src/app/domain/suprimento/produto-unidade-medida';
import { ProdutoFornecedor, InsumoDoFornecedor, OrigemProduto } from 'src/app/domain/suprimento/produto-fornecedor';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { Pagination } from 'src/app/domain/common/pagination';
import { ProdutoCategoriaNivel, ComponetesSelecao, TipoSelecao } from 'src/app/domain/suprimento/produto-categoria-nivel';
import { CategoriaNivelService } from 'src/app/service/suprimento/categoria-nivel.service';
import { SuprimentoService } from 'src/app/service/suprimento/suprimento-service';
import { Suprimento } from 'src/app/domain/suprimento/suprimento';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { CategoriaVirtual } from 'src/app/domain/suprimento/categoria-virtual';
import { CategoriaVirtualService } from 'src/app/service/suprimento/categoria-virtual.service';
import { VinculoNivelService } from 'src/app/service/suprimento/vinculo-nivel.service';
import { RelacaoProdutoNivel } from 'src/app/domain/suprimento/relacao-produto-nivel';
import { ProdutoComprador, RelacaoProdutoMarcaComprador } from 'src/app/domain/suprimento/comprador/produto-comprador';
import { ProdutoCompradorService } from 'src/app/service/suprimento/comprador/produto-comprador.service';
import { GlobalVariable } from 'src/app/core/global-variable';
import { ParamsTruck } from '../entrega-parcial-comprador/entrega-parcial-comprador.component';
import { Router, ActivatedRoute } from '@angular/router';
import { CotacaoService } from 'src/app/service/suprimento/comprador/cotacao.service';
import { Cotacao, EnumStatusCotacao } from 'src/app/domain/cotacao/cotacao';
import { EntregaParcialComprador } from 'src/app/domain/suprimento/comprador/entrega-parcial-comprador';
import { RepositoryService } from 'src/app/service/repository.service';
import { Marca, RelacaoObraProduto } from 'src/app/domain/suprimento/marca';
import { EnumState } from 'src/app/domain/common/interactiveTable';

declare var $: any;

@Component({
  selector: 'app-insumo-comprador',
  templateUrl: './insumo-comprador.component.html',
  styleUrls: ['./insumo-comprador.component.css'],
  providers: [ProdutoService]
})

export class InsumoCompradorComponent implements OnInit {



  //cotacao_id = this.router.snapshot.queryParams['id'];
  _input_cotacao = "";

  _code = 'app-insumo-comprador-remove';
  _code_img = 'app-insumo-comprador-remove-img';

  _list_produtos = new Array<ProdutoComprador>();

  _produto_selecionado: ProdutoComprador;

  public _input_nivel: ProdutoCategoriaNivel;

  public _suprimento: Suprimento;
  public _categorias: ProdutoCategoria[];
  public _unidade_medida: ProdutoUnidadeMedida[];
  public _ha_produto: boolean;
  public _carregando: boolean;

  count_nivel = 0;
  public _categoria_nivel: boolean;
  public _niveis: Array<ProdutoCategoriaNivel>;

  public _buscar_produto: string;
  public _categoria_id: number;
  public _vinculado: boolean;
  public _loading_save: boolean;

  private delete_produto: ProdutoComprador;

  public _categoria_virtual: CategoriaVirtual;

  public _enumComponetesSelecao = ComponetesSelecao;
  cotacao_id = this.route.snapshot.params['id'];
  public _pesquisar: string;
  
  constructor(
    private repository: RepositoryService,
    private httpProduto: ProdutoService,
    private httpSuprimentos: SuprimentoService,
    private confirmService: AlertConfirmService,
    private produtoCompradorService: ProdutoCompradorService,
    private route: ActivatedRoute,
    private cotacaoService: CotacaoService,
    private router: Router) {
    this._produto_selecionado = new ProdutoComprador();
    this.confirmService.UnsetObservers();
    this.confirmService.confirmEmitter$.subscribe(c => { this.DeleteProduct(c); this.RemoveImg(c); });
    this._carregando = false;
    this._ha_produto = false;
    this._buscar_produto = '';
    this._categoria_id = 0;
    this._suprimento = new Suprimento();
    this._suprimento.insumos = new Array<InsumoDoFornecedor>();
    this._input_nivel = new ProdutoCategoriaNivel();
    this._categorias = new Array<ProdutoCategoria>();
  }


  ngOnInit() {
    this._niveis = new Array<ProdutoCategoriaNivel>();
    this.SetListaUnidadeMedida();
    this.SetListaCategorias();
    this.GetCotacao(this.cotacao_id);
    this.GetAll(this.cotacao_id);
  }

  _cotacao = new Cotacao();
  GetCotacao(id: number) {
    this.cotacaoService.Get(id).subscribe(
      d => { this._cotacao = d; },
      e => { },
      () => {
        if (this._cotacao.status == EnumStatusCotacao.enviada)
          this.router.navigateByUrl("/cotacao/view/" + this._cotacao.id);
      }
    );
  }

  AdicionarInsumo() {
    $("#pesquisa-insumo-modal").modal("show");
  }

  OpenModalTrunck(produto: ProdutoComprador) {
    if (produto.unidade_id < 1)
      throw "Selecione uma unidade!";
    if (produto.quantidade <= 0)
      throw "Preencha a quantidade!";
    this._produto_selecionado = produto;
    this._produto_selecionado._unidade_nome = ProdutoUnidadeMedida.Get(this._unidade_medida, produto.unidade_id).nome;
    $("#data-entraga-parcial-modal").modal("show");
  }

  ReceiverProdutos(data: any) {
    const model = new ProdutoComprador();
    model._produto_obra = new RelacaoObraProduto()
    model.produto_obra_id = data.id
    model.produto_id = data.produto_id
    model.categoria_id = data._categoria_id
    model.unidade_id = data.unidade_id
    model.nome = data._nome
    model.quantidade = data.quantidade
    model.origem = data.origem
    model.cotacao_id = this._cotacao.id
    model._marcas = data._marcas
    
    this.produtoCompradorService.AddProductRange(model).subscribe(d => {
      this.tempModel._toSon = false
      d._produto_obra = new RelacaoObraProduto()
      d._index = this.tempModel._index + 1
      d._produto_obra.previsto_total = UseFullService.ConvertFloat(data.previsto_total)
      this._list_produtos[this.tempModel._index] = d
      if (this._list_produtos.length - 1 === this.tempModel._index) {
        this.SetConfig(true)
      }
    },
      e => { }, () => { /*this.GetAll(this.cotacao_id);*/ }
    );
  }

  saveMarcas(produto: any, list: Array<Marca>) {
    this.repository.path = "supplies/buyer/createMarca"
    list.forEach(c => {
      const relacao = new RelacaoProdutoMarcaComprador()
      relacao.produto_comprador_id = produto.id
      relacao.marca_id = c.id
      produto._marcas.push(relacao)
    })
    this.repository.post(produto._marcas).subscribe(d => {
      produto._marcas = d;
      this.selectMarcas()
    })
  }

  GetAll(cotacao_id: number) {
    this.produtoCompradorService.GetAll(cotacao_id).subscribe(
      d => { this._list_produtos = d; },
      e => { },
      () => {
        this._list_produtos.forEach(c => { if (c.quantidade <= 0) { c.quantidade = undefined; } });
        this.SetConfig(true);
      }
    );
  }

  SetConfig(insert = false) {
    if (insert)
      this.verificaIndex();
    for (let i = 0; i < this._list_produtos.length; i++) {
      this._list_produtos[i]._index = i + 1;
      this._list_produtos[i]._name_file = this._list_produtos[i].anexo;
      var qtd_prevista = this._list_produtos[i]._produto_obra.previsto_total;
      if (this._list_produtos[i]._produto_obra){
        if(!(String(qtd_prevista).match(',')))
          this._list_produtos[i]._produto_obra.previsto_total = UseFullService.ToFixedReal(qtd_prevista)
      }else
        this._list_produtos[i]._produto_obra = new RelacaoObraProduto()
      //this._list_produtos[i]._categoria = ProdutoCategoria.GetName(this._categorias, this._list_produtos[i].categoria_id);
      if(this._list_produtos[i].quantidade <= 0)
        delete this._list_produtos[i].quantidade        
    }
  }

  _tempModel = new ProdutoComprador()
  verificaIndex() {
    var newProduto = new ProdutoComprador()
    newProduto._produto_obra = new RelacaoObraProduto()
    this._list_produtos.push(newProduto)
    this.tempModel = new ProdutoComprador()
    this.tempModel._index = this._list_produtos.length - 1
    this._buscar_produto = ''
    setTimeout(() => { $('#_search_').focus(); }, 500);
  }

  SaveProduct(data: ProdutoComprador) {
    if (!data._status)
      return;
    data._loading = true;
    data.quantidade = data.quantidade || 0
    this.produtoCompradorService.SetEditProduct(data).subscribe(
      d => { },
      e => { setTimeout(() => { data._loading = false; }, 1500); },
      () => { data._loading = false; data._status = false; data._name_file = data.anexo; }
    );
  }

  ConfirmDeleteProduct(data: ProdutoComprador) {
    this.delete_produto = data;
    this.confirmService._code = this._code;
    this.confirmService._title = "Excluir produto da cotação";
    this.confirmService._message = "Deseja remover o produto <b>" + data.nome + "</b>, da cotação <b>" + this._cotacao.nome + "</b>?";
    this.confirmService.Show();
  }

  ConfirmRemoveImg(data: ProdutoComprador) {
    this.delete_produto = data;
    this.confirmService._code = this._code_img;
    this.confirmService._title = "Remover imagem do produto";
    this.confirmService._message = "Deseja remover o arquivo <b>" + data.anexo + "</b>, do produto <b>" + data.nome + "</b>?";
    if (data.anexo.match(".png") || data.anexo.match(".jpg") || data.anexo.match(".gif"))
      this.confirmService._message += "<br /><div class='text-center'><img class='confirm-img'  src='" + GlobalVariable.BASE_API_URL + "comprador/cotacao_" + data.cotacao_id + "/" + data.id + "_" + data.anexo + "' /></div>";
    this.confirmService.Show();
  }

  RemoveImg(confirm: boolean) {
    if (!this.delete_produto || !confirm || this.confirmService._code != this._code_img)
      return;
    this.delete_produto.anexo = ''
    this.delete_produto._name_file = ''
    this.delete_produto._file = null
    this.OnChangeAll(this.delete_produto)
    this.delete_produto = null
  }

  load = false
  DeleteProduct(confirm: boolean) {
    if (!this.delete_produto || !confirm || this.confirmService._code != this._code)
      return;
    this.load = true
    this.produtoCompradorService.Delete(this.delete_produto.id).subscribe(
      d => { this._list_produtos.splice((this.delete_produto._index - 1), 1) },
      e => { this.load = true },
      () => {
        this.GetAll(this.cotacao_id)
        this.load = false
      }//this.GetAll(this.cotacao_id) }
    );
  }

  _input = new Array<Marca>()
  tempInsumo = new ProdutoComprador()
  showModalMarcas(insumo) {
    this.tempInsumo = insumo
    $('#modal-multiselect').modal('show')
    if (this._input.length < 1) { this.GetAllMarcas() } else { this.selectMarcas() }
  }

  selectMarcas() {
    this._input.forEach(c => { c._selected = false; c._state = EnumState.none })
    for (let i = 0; i < this.tempInsumo._marcas.length; i++) {
      const marca = this._input.filter(o => { return (o.id == this.tempInsumo._marcas[i].marca_id) })
      if (marca && marca.length) {
        marca[0]._selected = true
        marca[0]._state = EnumState.none
      }
    }
  }

  GetAllMarcas() {
    this.repository.path = 'supplies/marca/getall';
    this.repository.getAll().subscribe(d => { this._input = d; this.selectMarcas(); });
  }

  receiverData(list: Array<Marca>) {
    this.repository.path = "supplies/buyer/createMarca"
    list.filter(o => { return (o._selected || o._state == EnumState.delete) }).forEach(c => {
      const data = this.tempInsumo._marcas.filter(o => { return (o.marca_id == c.id) })
      if (c._state == EnumState.delete) {
        if (data && data.length > 0) { data[0]._state = EnumState.delete }
      } else if (!data || data.length < 1) {
        const relacao = new RelacaoProdutoMarcaComprador()
        relacao.produto_comprador_id = this.tempInsumo.id
        relacao.marca_id = c.id
        this.tempInsumo._marcas.push(relacao)
      }
    })
    this.repository.post(this.tempInsumo._marcas).subscribe(d => {
      this.tempInsumo._marcas = d;
      this.selectMarcas()
    })
  }

  SetListaUnidadeMedida() {
    this.httpProduto.GetAllUnitsMeasured().subscribe(
      data => { this._unidade_medida = data; },
      error => { },
      () => { }
    );
  }

  SetListaCategorias() {
    this.httpProduto.getAllCategories().subscribe(
      data => { this._categorias = data; },
      error => { },
      () => {
        if (this._list_produtos.length >= 1)
          this.SetConfig();
      }
    );
  }

  OnChangeAll(data: ProdutoComprador) {
    setTimeout(() => {
      data._status = true
      this.SaveProduct(data);
      //data._status = (data.unidade_id >= 1 && data.quantidade) ? true : false;
    }, 10);
  }

  /************** Functions file **************/
  CarregarFile(data: ProdutoComprador, input: any) {
    if (input.files && input.files[0]) {
      data.anexo = input.files[0].name;
      data._file = input.files[0];
    }
    this.OnChangeAll(data);
  }
  public _nome_arquivo: string;
  public _src_arquivo: string;
  MostrarFile(data: ProdutoComprador) {
    if (data._file) {

      var file = data._file;
      this._nome_arquivo = file.name;
      var _this = this;
      var reader = new FileReader();
      reader.onload = function (e) {
        var target = e.target as any;

        if (file.type == "application/pdf") {
          _this._src_arquivo = undefined;
          setTimeout(() => {
            $("#exibir-pdf").html("<iframe width='100%' height='" + ($('body').height() - 100) + "px' src='" + target.result + "'></iframe>");
          }, 10);
        } else
          _this._src_arquivo = target.result;
      };
      reader.readAsDataURL(file);
    } else
      this.CarregarDataModal(data);
    $('#data-modal').modal('show');
  }

  CarregarDataModal(data: ProdutoComprador) {
    this._nome_arquivo = "";
    this._src_arquivo = "";
    $("#exibir-pdf").html("");

    this._nome_arquivo = data.anexo;
    if (!data.anexo.match(".png") && !data.anexo.match(".jpg") && !data.anexo.match(".gif")) {
      this._src_arquivo = undefined;
      setTimeout(() => {
        $("#exibir-pdf").html("<iframe width='100%' height='" + ($('body').height() - 100) + "px' src='" + GlobalVariable.BASE_API_URL + "comprador/cotacao_" + data.cotacao_id + "/" + data.id + "_" + data.anexo + "'></iframe>");
      });
    } else
      this._src_arquivo = GlobalVariable.BASE_API_URL + "comprador/cotacao_" + data.cotacao_id + "/" + data.id + "_" + data.anexo;
  }

  ReceiverEntregaProduto(data: Array<EntregaParcialComprador>) {
    if (data && data.length > 0) {
      data.forEach(c => {
        c.data_entrega = new Date(UseFullService.formatDateUS(String(c._data_entrega)));
        c.produto_comprador_id = this._produto_selecionado.id;
      });
      setTimeout(() => {
        this._produto_selecionado._entregaParcial = JSON.stringify(data);
        this.OnChangeAll(this._produto_selecionado);
      }, 100);
    }
  }

  Reload() { this.router.navigateByUrl("/cotacao/edit-cotacao/" + this._cotacao.id); }

  Right() {
    try {
      if (this._list_produtos.length < 1)
        throw ("Cotação deve ter produtos!")
      this._list_produtos.forEach(c => {
        if (c.id) {
          if (c.unidade_id <= 0 || (!c.quantidade || c.quantidade <= 0)) {
            throw ("Todos os produtos devem ter unidade e quantidade!");
          } else if (c._status) {
            throw ("Salve as alterações para continuar!");
          }
        }
      })

      this.router.navigateByUrl("/cotacao/view/" + this._cotacao.id);
    } catch (error) {
      throw (error);
    }
  }

  mouseEnter(item: any) { item._selected_hover = true }

  mouseLeave(item: any) { item._selected_hover = false }

  tempModel = new ProdutoComprador()
  addRow(model: any) {
    for (let i = 0; i < this._list_produtos.length; i++) {
      if (!this._list_produtos[i].id)
        this._list_produtos.splice(this._list_produtos[i]._index - 1, 1)
    }
    this._buscar_produto = ''
    model._toSon = true
    this._list_produtos.splice(model._index, 0, new ProdutoComprador())
    this.SetConfig()
    this.tempModel = model
    setTimeout(() => { $('#_search_').focus(); }, 500);
  }

  removeRow(model: any) {
    model._toSon = false
    this._list_produtos.splice(model._index, 1)
    this.tempModel = new ProdutoComprador()
  }
}