import { EntregaParcialComprador } from './entrega-parcial-comprador';
import { OrcamentoProduto } from '../../orcamento/orcamento-produto';
import { Orcamento } from '../../orcamento/orcamento';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { InteractiveTable } from '../../common/interactiveTable';
import { RelacaoProdutoMarcaCategoria, RelacaoObraProduto } from '../marca';
import { ProdutoFornecedor } from '../produto-fornecedor';

export class ProdutoComprador extends InteractiveTable {

    _historico_valor_orcado: string
    _valor_sugerido: string
    _categoria_index: number

    _loading = false;
    _status = false;
    _orcamento_produto = new OrcamentoProduto();

    public id: number;
    public cotacao_id: number;
    //
    // produto_id, se o produto for virtual o valor será sempre 0 caso contrario id de referencia da tabela de produtos. 
    //
    public produto_id: number;
    public produto_obra_id: number;
    public categoria_id: number;
    public unidade_id: number;
    public origem: number;

    public quantidade: number;
    public nome: string;
    public anexo: string;
    public data_criacao: Date;
    public estoque: number;

    public _quantidade: string;
    public _preco_registrado: string;

    public _niveis: string;
    //public _loading: boolean;
    //public _status: boolean;
    public _categoria: string;
    public _index: number;
    public _name_file: string;
    public _file: File;
    public _unidade_nome: string;
    public _qt_entrega_parcial: number;
    public _index_impar_par: boolean;

    public _marcas: Array<RelacaoProdutoMarcaComprador>
    public _produto_obra: RelacaoObraProduto
    public ProdutoFornecedor: ProdutoFornecedor

    //public _orcamento_produto: OrcamentoProduto;

    static SetProdutoOrcamento(list_model: ProdutoComprador[], list_data: OrcamentoProduto[], orcamento: Orcamento) {
        if (!list_data)
            return;

        var id = 0;
        function ObterPorId(obj) {
            return (obj.produto_comprador_id == id);
        }

        list_model.forEach(c => {
            id = c.id;
            var data = list_data.filter(ObterPorId);
            if (data && data.length > 0) {
                const orcamentoProduto = data[0]
                orcamentoProduto._icms_string = orcamentoProduto.icms.toFixed(2).replace('.', ',');
                orcamentoProduto._ipi_string = orcamentoProduto.ipi.toFixed(2).replace('.', ',');
                if (orcamentoProduto.icms > 0)
                    orcamentoProduto._icms = orcamentoProduto.icms;
                if (orcamentoProduto.ipi > 0)
                    orcamentoProduto._ipi = orcamentoProduto.ipi;
                if (parseFloat(orcamentoProduto.preco) > 0)
                    orcamentoProduto._preco = orcamentoProduto.preco;

                var preco_sugerido = parseFloat(orcamentoProduto.preco_sugerido)
                if(parseFloat(orcamentoProduto.preco_registrado) > 0 && preco_sugerido > parseFloat(orcamentoProduto.preco_registrado))
                    preco_sugerido = parseFloat(orcamentoProduto.preco_registrado);
                if(parseFloat(orcamentoProduto.preco) > 0 && preco_sugerido > parseFloat(orcamentoProduto.preco))
                    preco_sugerido = parseFloat(orcamentoProduto.preco);

                orcamentoProduto._preco_string = UseFullService.ToFixedReal(orcamentoProduto.preco);
                orcamentoProduto._preco_registrado = UseFullService.ToFixedReal(orcamentoProduto.preco_registrado);
                orcamentoProduto._preco_sugerido = UseFullService.ToFixedReal(preco_sugerido);

                if (c._marcas && c._marcas.length > 0 && orcamentoProduto.marca_id > 0)
                    orcamentoProduto._marca = c._marcas.filter(o => { return (o.marca_id == orcamentoProduto.marca_id) })[0]._descricao

                OrcamentoProduto.SetCalculos(orcamentoProduto, c.quantidade, orcamento);
                c._orcamento_produto = orcamentoProduto;

            } else {
                c._orcamento_produto._icms = 0
                c._orcamento_produto._ipi = 0
                c._orcamento_produto._preco = "0,00"
                c._orcamento_produto._preco_string = "0,00"
                c._orcamento_produto._preco_registrado = "0,00"
                c._orcamento_produto._preco_sugerido = "0,00"
                c._orcamento_produto._preco_total = "0,00"
            }
        });
    }

    public _entregaParcial: string;
    public EntregasParcial: Array<EntregaParcialComprador>;
    public listMarcas: Array<EntregaParcialComprador>;

    public static FiltrarPorNome(produtos: Array<ProdutoComprador>, nome: string): ProdutoComprador {
        function filtrar(obj) {
            return ("nome" in obj && obj.nome == nome)
        }
        var produto = produtos.filter(filtrar);
        return produto[0];
    }
}

export class RelacaoProdutoMarcaComprador {
    public id: number
    public produto_comprador_id: number
    public marca_id: number
    public _descricao: string
    public _state: any
}